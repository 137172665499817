import React from "react"
//import { Link } from "gatsby"

import Layout from "../../components/es/layout"
//import Image from "../components/image"
import SEO from "../../components/es/seo"

import MainImg from "./../../images/main_bg.jpg"

import shape1 from "./../../images/dec/1.png"
import shape2 from "./../../images/dec/2.png"
import shape3 from "./../../images/dec/3.png"
import shape4 from "./../../images/dec/4.png"
import shape5 from "./../../images/dec/5.png"
import shape6 from "./../../images/dec/6.png"
import shape7 from "./../../images/dec/7.png"
import shape8 from "./../../images/dec/8.png"

const ZastitaPodataka = () => (
  <Layout>
    <SEO 
    title="MOONAMAR – POLÍTICA DE PRIVACIDAD"
    description="A continuación, explicamos qué información recopilamos cuando visita www.moonamar.co y cómo se utiliza."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">INTIMIDAD</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns">
              <div className="column _headline">
                <div className="content">
                <p><strong>ALLGEMEINES</strong></p>
<p>Wir von &bdquo;MOONAMAR e.U.&ldquo; freuen uns &uuml;ber Ihren Besuch auf unserer Webseite <a href="http://www.moonamar.co">www.moonamar.co</a> und Ihrem Interesse an unserem Unternehmen und dessen Dienstleistungen. Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst und wir m&ouml;chten, dass Sie sich bei jedem Besuch unserer Webseite sicher f&uuml;hlen.</p>
<p>Im Folgenden erl&auml;utern wir, welche Informationen wir bei <a href="http://www.moonamar.co">www.moonamar.co</a> w&auml;hrend Ihres Besuches auf unserer Webseite erfassen und wie diese genutzt werden.</p>
<p><em>Die vorliegende Datenschutzerkl&auml;rung wurde zum letzten Mal am 01. Januar 2020 angepasst.</em></p>
<p></p>
<p><strong>VON UNS VERARBEITETE PERSONENBEZOGENE DATEN</strong></p>
<p>MOONAMAR e.U. verarbeitet Ihre personenbezogenen Daten, weil Sie unsere Dienstleistungen nutzen und/oder weil Sie diese Daten uns selbst zur Verf&uuml;gung gestellt haben.&nbsp;</p>
<p>Personenbezogene Daten sind Einzelangaben &uuml;ber pers&ouml;nliche oder sachliche Verh&auml;ltnisse einer bestimmten oder bestimmbaren nat&uuml;rlichen Person. Darunter fallen Informationen wie z.B. Ihr richtiger Name, Ihre Anschrift, Ihre Telefonnummer und Ihr Geburtsdatum. Informationen, die nicht direkt mit Ihrer wirklichen Identit&auml;t in Verbindung gebracht werden - wie z.B. favorisierte Webseiten oder die Anzahl der Nutzer einer Seite - sind keine personenbezogenen Daten.</p>
<p>Nachstehend finden Sie eine &Uuml;bersicht der personenbezogenen Daten (sofern richtig angegeben), die wir verarbeiten:</p>
<ul>
<li>Vor- und Nachname</li>
<li>Adressdaten</li>
<li>Telefonnummer</li>
<li>E-Mail-Adresse</li>
<li>Ortsangaben</li>
<li>Zahlungsdaten (sofern zutreffend)</li>
<li>Sonstige personenbezogene Daten, die Sie aktiv zur Verf&uuml;gung stellen, zum Beispiel bei der Korrespondenz und telefonisch</li>
<li>Daten &uuml;ber Ihre Aktivit&auml;ten auf unserer Website</li>
<li>Daten &uuml;ber Ihr Surfverhalten auf unterschiedlichen Websites (zum Beispiel, weil dieses Unternehmen ein Teil eines Werbenetzwerks ist)</li>
<li>IP-Adresse</li>
<li>Internetbrowser und Ger&auml;tetyp</li>
</ul>
<p>Wir k&ouml;nnen nicht kontrollieren, ob ein Besucher &auml;lter als 16 Jahre ist. Wir raten Eltern deswegen, die Online-Aktivit&auml;ten unter Kontrolle zu haben, um auf diese Weise zu vermeiden, dass die Daten der Kinder ohne Zustimmung der Eltern erfasst werden. Wenn Sie der Meinung sind, dass wir ohne Zustimmung personenbezogene Daten eines Minderj&auml;hrigen erfasst haben, nehmen Sie bitte unter <a href="mailto:info@moonamar.co">info@moonamar.co</a> Kontakt mit uns auf, wir werden dann die Informationen l&ouml;schen.</p>
<p></p>
<p><strong>ERHEBUNG UND VERARBEITUNG VON PERSONENBEZOGENEN DATEN</strong></p>
<p>Die personenbezogenen Daten werden durch uns in einer Datenbank gespeichert. Die Datenbank ist durch geeignete Ma&szlig;nahmen gegen Zugriffe von au&szlig;en gesch&uuml;tzt.</p>
<p>Die L&ouml;schung der gespeicherten personenbezogenen Daten erfolgt, wenn Sie Ihre Einwilligung zur Speicherung widerrufen, wenn ihre Kenntnis zur Erf&uuml;llung des mit der Speicherung verfolgten Zwecks nicht mehr erforderlich ist oder wenn ihre Speicherung aus sonstigen gesetzlichen Gr&uuml;nden unzul&auml;ssig ist.</p>
<p>Mit Ihrem Zugriff auf diese Webseite werden m&ouml;gliche Identifizierungsdaten (IP-Adresse) und weitere Angaben (Datum, Uhrzeit, betrachtete Seite) auf unserem Server f&uuml;r Zwecke der Datensicherheit tempor&auml;r gespeichert. Eine andere Auswertung der Daten, mit Ausnahme f&uuml;r statistische Zwecke und dann in anonymisierter Form, erfolgt nicht.</p>
<p><strong><br /></strong><strong>NUTZUNG UND WEITERGABE DER PERSONENBEZOGENEN DATEN</strong></p>
<p>Durch das Ausf&uuml;llen des Online-Formulars werden nur Daten erhoben, die f&uuml;r die sichere Abwicklung einer Kontaktaufnahme erforderlich sind.</p>
<p>Die E-Mail-Adresse dient zur Zusendung einer Empfangsbest&auml;tigung und bei R&uuml;ckfragen zur schnellen Kontaktaufnahme mit Ihnen. Ihre Telefonnummer wird f&uuml;r eventuelle R&uuml;ckfragen ben&ouml;tigt. Die Preisgabe aller Nutzerdaten erfolgt ausdr&uuml;cklich auf freiwilliger Basis. Es erfolgt keine Weiterleitung an Dritte. Der Nutzer kann sich an dieser Stelle das Formular mit diesen Daten ausdrucken.</p>
<p>Sie haben das Recht, eine erteilte Einwilligung mit Wirkung f&uuml;r die Zukunft jederzeit zu widerrufen. In keinem Fall werden die erhobenen Daten verkauft oder aus anderen Gr&uuml;nden als eben genannt an Dritte weitergegeben.&nbsp;</p>
<p></p>
<p><strong>Wie lange wir die Daten aufbewahren</strong></p>
<p>MOONAMAR e.U. wird Ihre personenbezogenen Daten nicht l&auml;nger speichern, als strikt notwendig f&uuml;r die Realisierung der Zwecke, f&uuml;r die die Daten erhoben wurden. Die personenbezogenen Daten werden bis zu zwei Jahren nach Ihrer Bestellung gespeichert, sofern MOONAMAR e.U. nicht aufgrund gesetzlicher Vorschriften eine l&auml;ngere Speicherzeit einhalten muss.</p>
<p></p>
<p><strong>EINSATZ VON COOKIES</strong></p>
<p>Cookies sind Dateien, die auf der Festplatte Ihres Computers abgelegt werden und die Navigation erleichtern. Sie vermeiden, dass bei jeder Nutzung alle erforderlichen Daten neu eingegeben werden m&uuml;ssen. Cookies erleichtern allgemein die Nutzung von Internet-Diensten. Sie k&ouml;nnen das Speichern von Cookies auf Ihrer Festplatte verhindern, indem Sie in Ihren Browser-Einstellungen "keine Cookies akzeptieren" w&auml;hlen. Sie k&ouml;nnen Ihren Browser auch so einstellen, dass dieser Sie vor dem Setzen von Cookies fragt, ob Sie einverstanden sind. Schlie&szlig;lich k&ouml;nnen Sie auch einmal gesetzte Cookies jederzeit wieder l&ouml;schen. Wie all das im Einzelnen funktioniert, entnehmen Sie bitte der Anleitung Ihres Browser-Herstellers. Wenn Sie keine Cookies akzeptieren, kann dies im Einzelfall zu Funktionseinschr&auml;nkungen der FrischerGehts.net Dienste f&uuml;hren.</p>
<p>Sofern Sie in Ihrem Browser Cookies zulassen, k&ouml;nnen Sie optional die Daten der Anschrift beim Absenden einer Bestellung als Cookies auf Ihrem Rechner speichern, so dass diese bei einer erneuten Bestellung nicht mehr eingegeben werden m&uuml;ssen. Au&szlig;erhalb unserer Webseite sind die Cookies bedeutungslos und k&ouml;nnen nicht von Dritten eingesehen oder verwendet werden.</p>
<p></p>
<p><strong>SERVER-LOG-FILES</strong></p>
<p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:&nbsp;</p>
<ul>
<li>Browsertyp und Browserversion&nbsp;</li>
<li>verwendetes Betriebssystem&nbsp;</li>
<li>Referrer URL&nbsp;</li>
<li>Hostname des zugreifenden Rechners&nbsp;</li>
<li>Uhrzeit der Serveranfrage&nbsp;</li>
<li>IP AdresseSocial&nbsp;</li>
</ul>
<p>Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachtr&auml;glich zu pr&uuml;fen, wenn uns konkrete Anhaltspunkte f&uuml;r eine rechtswidrige Nutzung bekannt werden.</p>
<p><strong><br /></strong><strong>VERWENDUNG VON SOCIAL-PLUGINS</strong></p>
<p>Facebook Plugin</p>
<p>Auf unserer Website werden sogenannte Social Plugins ("Plugins") des sozialen Netzwerkes Facebook verwendet, das von der Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA ("Facebook") betrieben wird. Die Plugins sind mit einem Facebook-Logo oder dem Zusatz "Soziales Plug-in von Facebook" bzw. "Facebook Social Plugin" gekennzeichnet. Eine &Uuml;bersicht &uuml;ber die Facebook Plugins und deren Aussehen finden Sie hier: https://developers.facebook.com/docs/plugins</p>
<p>Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enth&auml;lt, stellt Ihr Browser eine direkte Verbindung zu den Servern von Facebook her. Der Inhalt des Plugins wird von Facebook direkt an Ihren Browser &uuml;bermittelt und in die Seite eingebunden. Durch diese Einbindung erh&auml;lt Facebook die Information, dass Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat, auch wenn Sie kein Facebook-Profil besitzen oder gerade nicht bei Facebook eingeloggt sind. Diese Information (einschlie&szlig;lich Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen Server von Facebook in die USA &uuml;bermittelt und dort gespeichert.</p>
<p>Sind Sie bei Facebook eingeloggt, kann Facebook den Besuch unserer Website Ihrem Facebook-Profil unmittelbar zuordnen. Wenn Sie mit den Plugins interagieren, zum Beispiel den "Gef&auml;llt mir"-Button bet&auml;tigen oder einen Kommentar abgeben, wird diese Information ebenfalls direkt an einen Server von Facebook &uuml;bermittelt und dort gespeichert. Die Informationen werden au&szlig;erdem auf Ihrem Facebook- Profil ver&ouml;ffentlicht und Ihren Facebook-Freunden angezeigt.</p>
<p>Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre diesbez&uuml;glichen Rechte und Einstellungsm&ouml;glichkeiten zum Schutz Ihrer Privatsph&auml;re entnehmen Sie bitte den Datenschutzhinweisen von Facebook: http://www.facebook.com/policy.php</p>
<p>Wenn Sie nicht m&ouml;chten, dass Facebook die &uuml;ber unseren Webauftritt gesammelten Daten unmittelbar Ihrem Facebook-Profil zuordnet, m&uuml;ssen Sie sich vor Ihrem Besuch unserer Website bei Facebook ausloggen. Sie k&ouml;nnen das Laden der Facebook Plugins auch mit Add-Ons f&uuml;r Ihren Browser komplett verhindern, z.B. mit dem "Facebook Blocker" (<a href="http://webgraph.com/resources/facebookblocker/">http://webgraph.com/resources/facebookblocker/</a>).</p>
<p>Instagram Plugin</p>
<p>Auf dieser Website sind Inhalte des sozialen Netzwerks Instagram eingebunden. Wenn Sie in Ihrem Instagram-Account eingeloggt sind, kann Instagram m&ouml;glicherweise den Besuch unserer Seiten zu Ihrem Benutzerkonto zuordnen. Wir weisen ausdr&uuml;cklich darauf hin, dass wir als Anbieter der Seite keine Kenntnis vom Inhalt der &uuml;bermittelten Daten, sowie deren Nutzung durch Instagram erhalten. Weitere Informationen zur Datenschutzerkl&auml;rung von Instagram k&ouml;nnen unter dem folgenden Link eingesehen werden: http://instagram.com/about/legal/privacy/</p>
<p><strong>NEWSLETTER</strong></p>
<p>Sie haben im Rahmen unserer Dienste die M&ouml;glichkeit, unseren Newsletter zu abonnieren. Durch die Registrierung f&uuml;r unseren Newsletter erkl&auml;ren Sie sich damit einverstanden, dass wir Ihre E-Mail-Adresse speichern und Ihnen aktuelle Informationen an diese Adresse senden. Im Rahmen des Newsletter-Versands leiten wir weder Ihre E-Mail-Adresse noch sonstige pers&ouml;nliche Informationen &uuml;ber Sie an Dritte weiter. Jeder Newsletter enth&auml;lt eine Information &uuml;ber die Abbestellung des Newsletters.</p>
<p></p>
<p><strong>SICHERHEIT</strong></p>
<p>MOONAMAR e.U. bem&uuml;ht sich Ihre Daten vor unbefugtem Zugriff, Verlust, Missbrauch oder Zerst&ouml;rung zu sch&uuml;tzen. Ihre pers&ouml;nlichen Daten sind nur solchen Mitarbeitern zug&auml;nglich, die notwendigerweise Einsicht in Ihre Daten haben m&uuml;ssen, um ihre T&auml;tigkeit ordnungsgem&auml;&szlig; aus&uuml;ben und Ihnen unsere Dienste und Produkte bestm&ouml;glich anbieten zu k&ouml;nnen. Au&szlig;erdem ergreifen wir zum Schutz Ihrer pers&ouml;nlichen Daten verfahrenstechnische und elektronische Schutzma&szlig;nahmen.</p>
<p></p>
<p><strong>GOOGLE ANALYTICS</strong></p>
<p>Diese Webseite benutzt Google Analytics, einen Webanalysedienst der Google Inc. (&bdquo;Google&rdquo;). Google Analytics verwendet sog. &bdquo;Cookies&rdquo;, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Sie erm&ouml;glichen. Die durch den Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser Webseite (einschlie&szlig;lich Ihrer IP-Adresse) werden an einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports &uuml;ber die Webseitenaktivit&auml;ten f&uuml;r die Webseitenbetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte &uuml;bertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google in Verbindung bringen. Sie k&ouml;nnen die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich nutzen k&ouml;nnen. Durch die Nutzung dieser Webseite erkl&auml;ren Sie sich mit der Bearbeitung der &uuml;ber Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.</p>
<p>Der Erhebung und Nutzung Ihrer IP-Adresse durch Google Analytics k&ouml;nnen Sie jederzeit mit Wirkung f&uuml;r die Zukunft widersprechen. N&auml;here Informationen hierzu finden Sie unter&nbsp;<a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>. Wir weisen Sie darauf hin, dass auf dieser Webseite Google Analytics um den Code&nbsp;<strong>&bdquo;_gaq.push(['_gat._anonymizeIp']);&rdquo;</strong>&nbsp;erweitert wurde, um eine <strong>anonymisierte Erfassung</strong>&nbsp;von IP-Adressen zu gew&auml;hrleisten.&nbsp;<br />Sie k&ouml;nnen die Erfassung durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, das die zuk&uuml;nftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert:&nbsp;<a href="about:blank">Google Analytics deaktivieren</a>.&nbsp;<br />Sollten Sie die Cookies in Ihrem Browser l&ouml;schen oder einen anderen Browser verwenden, m&uuml;ssen Sie diesen Vorgang wiederholen. N&auml;here Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter&nbsp;<a href="http://www.google.com/analytics/terms/de.html">http://www.google.com/analytics/terms/de.html</a>&nbsp;bzw. unter&nbsp;<a href="https://www.google.de/intl/de/policies/">https://www.google.de/intl/de/policies/</a>.</p>
<p></p>
<p><strong>FACEBOOK PIXEL&nbsp;</strong></p>
<p>Unsere Website verwendet die Remarketing-Funktion &bdquo;Facebook-Pixel&ldquo; der Facebook Inc. (&bdquo;Facebook&ldquo;). Diese Funktion dient dazu, Besuchern dieser Webseite im Rahmen des Besuchs des sozialen Netzwerkes Facebook interessenbezogene Werbeanzeigen (&bdquo;Facebook-Ads&ldquo;) zu pr&auml;sentieren. Hierzu wurde auf unserer Website Facebook-Pixel implementiert. &Uuml;ber Facebook-Pixel wird beim Besuch der Webseite eine direkte Verbindung zu den Facebook-Servern hergestellt. Dabei wird an den Facebook-Server &uuml;bermittelt, dass Sie diese Website besucht haben und Facebook ordnet diese Information Ihrem pers&ouml;nlichen Facebook-Benutzerkonto zu. N&auml;here Informationen zur Erhebung und Nutzung der Daten durch Facebook sowie &uuml;ber Ihre diesbez&uuml;glichen Rechte und M&ouml;glichkeiten zum Schutz Ihrer Privatsph&auml;re finden Sie in den Datenschutzhinweisen von Facebook unter https://www.facebook.com/about/privacy/. Alternativ k&ouml;nnen Sie die Remarketing-Funktion von Facebook unter https://www.facebook.com/settings/?tab=ads#_=_ deaktivieren. Hierf&uuml;r m&uuml;ssen Sie bei Facebook angemeldet sein.&nbsp;</p>
<p></p>
<p><strong>SSL-VERSCHL&Uuml;SSELUNG</strong></p>
<p>Diese Seite nutzt aus Gr&uuml;nden der Sicherheit und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.&nbsp;</p>
<p>Bei Fragen wenden Sie sich bitte an folgende E-Mail-Adresse: <a href="mailto:info@moonamar.co">info@moonamar.co</a></p>
<p><strong>&Auml;NDERUNG DIESER DATENSCHUTZERKL&Auml;RUNG</strong></p>
<p>Die schnelle technologische Entwicklung des Internets und die &Auml;nderungen von Recht und Gesetz im Bereich des Datenschutzes machen es erforderlich, dass wir unsere Datenschutzerkl&auml;rung von Zeit zu Zeit den neuen Anforderungen anpassen. Bitte beachten Sie daher die jeweils aktuelle Datenschutzerkl&auml;rung.</p>
<p>Sie k&ouml;nnen die aktuellen Datenschutzbestimmungen jederzeit unter dem Link "Datenschutz" abrufen und ausdrucken.</p>
<p><strong>KONTAKT</strong></p>
<p>Wenn Sie Fragen hinsichtlich der Verarbeitung Ihrer pers&ouml;nlichen Daten haben, so wenden Sie sich bitte an <a href="mailto:info@moonamar.co">info@moonamar.co</a>.</p>
<p><strong>Stand: Januar 2020</strong></p>
<p>Erinnere dich an das Windows XP Betriebssystem? Sie k&ouml;nnen mit ihm online unter <a href="https://geekprank.com/" target="_blank" rel="nofollow noopener">GeekPrank</a> arbeiten und Ihre Freunde betr&uuml;gen.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default ZastitaPodataka
